<template>
  <div>
    <TabView>
      <TabPanel header="Kategorien">
        <config_io_category></config_io_category>
      </TabPanel>
      <TabPanel header="Anlagen">
        <config_io_departments></config_io_departments>
      </TabPanel>
      <TabPanel header="IO-Typen">
        <config_io_types></config_io_types>
      </TabPanel>
      <TabPanel header="Einheiten">
        <config_io_unit></config_io_unit>
      </TabPanel>
      <TabPanel header="Server">
        <config_io_server></config_io_server>
      </TabPanel>
      <TabPanel header="Parameter">
        <config_io_parameter></config_io_parameter>
      </TabPanel>
      <TabPanel header="Icons">
        <config_io_icons></config_io_icons>
      </TabPanel>
    </TabView>
  </div>
</template>

<script>
// import { FilterMatchMode } from "primevue/api";
// import ProductService from "../helpers";
// import mainService from "@/services/mainService.js";
// import SocketioService from "@/services/socketioService.js";
// import socketioService from "@/services/socketioService.js";
import config_io_category from "@/components/config_io_category"
import config_io_departments from "@/components/config_io_departments"
import config_io_types from "@/components/config_io_types"
import config_io_unit from "@/components/config_io_unit"
import config_io_server from "@/components/config_io_server"
import config_io_parameter from "@/components/config_io_parameter"
import config_io_icons from "@/components/config_io_icons"

export default {
  name: "TypesTable",
  components: {
    config_io_category,
    config_io_departments,
    config_io_types,
    config_io_unit,
    config_io_server,
    config_io_parameter,
    config_io_icons
  },
  data() 
    {
    return {
      loading: false,
    };
  },
  productService: null,
  created() {
  },
  mounted() {
    this.loading = true;
  },
  methods: {
  },
};
</script>

<style lang="scss" scoped>
</style>